import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types'; 
import Color from 'color';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import SnackbarPartial from '../../Components/SnackbarPartial/';
import SignUp from '../../Components/SignUp/';
import Landing from '../../Components/Landing/';
import Login from '../../Components/Login/';
import AdditionalInfo from '../../Components/AdditionalInfo/';
import Divisions from '../../Components/Divisions/';
import Waivers from '../../Components/Waivers/';
import Billing from '../../Components/Billing/';
import Success from '../../Components/Success/';
import logo from '../../images/sbtbb-2019.png';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2c77bd' },
    secondary: { main: '#e84924' },
    error: red
  },
  typography: {
    useNextVariants: true
  },
});

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  switchWrapper: {
    padding: theme.spacing.unit + 2,
    maxWidth: '100%',
    overflowX: 'hidden'
  }
});

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      member: {
        id: 0,
        name: '',
        email: '',
        birthday: '',
        gender: '',
        gym: '',
        address: '',
        city: '',
        nation: '',
        phone: '',
        tshirt: '',
        height: '',
        weight: '',
        accessToken: '',
        registration: {},
        division: {}
      },
      error: false,
      errorMessage: ''
    }

    this.updateMember = this.updateMember.bind(this);
    this.toggleError = this.toggleError.bind(this);
    this.errorCatchHelper = this.errorCatchHelper.bind(this);
  }

  errorCatchHelper(err) {

    let errorMsg = '';
    // console.log(err)
    // If we get a more accurate error message
    if(err.json) {
      err.json().then(actualError => {
        
        const rootKey = Object.keys(actualError)[0];

        // Might be better to just hack this apart per view?
        if(typeof actualError[rootKey] === 'string') {
          return this.setState({error: true, errorMessage: actualError[rootKey]});
        }

        for(let key in actualError[rootKey]) {
          console.log(key, actualError[rootKey][key][0]);

          if(key === 'email' && actualError[rootKey][key][0].indexOf('been taken')) {
            return this.setState({
              error: true,
              errorMessage: 'CrossFit btwb account already exists with that email.',
              hackedLinkout: true
            })
          }

          errorMsg = errorMsg + key + ':';
          let arrayOfErrors = actualError.errors[key];
          for(let i = 0; i < arrayOfErrors.length; i++) {
            errorMsg = errorMsg + ' ' + (arrayOfErrors[i]);
            if(i < arrayOfErrors.length - 1) errorMsg = errorMsg + ', '
          }
          errorMsg = errorMsg + '.  '
        }

        return this.setState({
          error: true,
          errorMessage: errorMsg
        })
      })
    } else if(err.statusText) {
      // fail generically
      return this.setState({
        error: true,
        errorMessage: err.statusText
      })
    } else {

      if(typeof err === 'string') {
        if(err.indexOf('CrossFit btwb account already exists with that email') > -1) {
          return this.setState({
            error: true, 
            errorMessage: err, 
            hackedLinkout: true
          })
        } else {
          return this.setState({
            error: true,
            errorMessage: err.toString()
          })   
        }
      }

      return this.setState({
        error: true,
        errorMessage: err.toString()
      })
    }
  }

  toggleError() {
    if(this.state.error) {
      this.setState({
        error: false,
        errorMessage: '',
        hackedLinkout: false
      })
    }
  }

  // This updates our root members while keeping the actual fetches/actions separate.
  // I am trying SO HARD to avoid redux rn lol
  updateMember(memberStats, callbackFunc) {

    let newMember = Object.assign(this.state.member, memberStats)

    this.setState({
      member: newMember
    }, () => {
      if(callbackFunc) return callbackFunc();
      return;
    })
  }

  componentDidMount() {
    
    if(window.location.search) {
      let currentMember = this.state.member;
      if(window.location.search.split('?customer-hash=').length > 1) {
        let memberJson = JSON.parse(window.atob(window.location.search.split('customer-hash=')[1]));
        currentMember.name = memberJson.firstname + ' ' + memberJson.lastname;
        currentMember.email = memberJson.email;
        currentMember.city = memberJson.city;
        this.setState({
          member: currentMember
        })
      }
    }
    this.setState({
      loaded: true
    })
  }

  save() {
    return fetch('http://localhost:3000/sports/registrations/' + this.props.member.registration.id + '/pay_by_charge')
  }

  render() {
    return (
      <div className="App">
        <div 
          className="background-image animated"
          style={{
            opacity: this.state.loaded ? 0.2 : 0
          }}>
        </div>
        <MuiThemeProvider theme={theme}>
          <Router>
            <div>
              <header className="App-header">
                <Grid 
                  justify="center"
                  alignItems="center"
                  container
                  className="tc relative">
                  <div className="absolute top-0 left-0 app-header-image w-100 h-100">
                  </div>
                  <Grid
                    item
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}>
                  </Grid>
                  <Grid 
                    item
                    lg={2}
                    md={3}
                    sm={4}
                    xs={5}
                    className="pa3 ph2 tc mh-2">
                    <a href="https://www.crossfit.com/foundation/crossfitforkids" target="_blank" rel="noopener noreferrer">
                      <img src={logo} className="" alt="logo" />
                    </a>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={8}
                    sm={7}
                    xs={6}>

                  </Grid>
                </Grid>
              </header>
              <div className={this.props.classes.switchWrapper}>

                <Grid container justify="center" className="ttu tl mt3 mt0-ns">
                  <Grid item md={8}>
                    <h1 className="header-text dn db-ns">2019 Saved By The Barbell Challenge</h1>
                  </Grid>
                </Grid>
                <Switch>
                  <Route path="/register/sign-up" render={() =>
                    <SignUp 
                      errorCatchHelper={this.errorCatchHelper} 
                      updateMember={this.updateMember} 
                      history={this.props.history} 
                      member={this.state.member}
                    />
                  } />
                  <Route path="/register/login" render={() => 
                    <Login 
                      errorCatchHelper={this.errorCatchHelper}
                      updateMember={this.updateMember} 
                      history={this.props.history}
                      member={this.state.member}
                    />
                  } />
                  <Route path="/register/additional-info" render={() => 
                    <AdditionalInfo 
                      errorCatchHelper={this.errorCatchHelper}
                      member={this.state.member} 
                      updateMember={this.updateMember}
                    />
                  } />
                  
                  <Route path="/register/divisions" render={() => 
                    <Divisions 
                      errorCatchHelper={this.errorCatchHelper}
                      member={this.state.member}
                      updateMember={this.updateMember}
                    />
                  } />

                  <Route path="/register/waivers" render={() => 
                    <Waivers 
                      errorCatchHelper={this.errorCatchHelper}
                      member={this.state.member}
                      updateMember={this.updateMember}
                    />
                  } />

                  <Route path="/register/billing" render={() => 
                    <Billing 
                      errorCatchHelper={this.errorCatchHelper}
                      updateMember={this.updateMember}
                      member={this.state.member}
                      history={this.props.history} 
                    />
                  } />
                  <Route path="/register/success" exact render={() => 
                    <Success
                      member={this.state.member}
                    />
                  } />
                  <Route path="/register/" exact component={Landing} />
                  <Route component={Landing} />
                </Switch>
              </div>
              <SnackbarPartial 
                error={this.state.error} 
                toggleError={this.toggleError}
                message={this.state.errorMessage}
                hackedLinkout={this.state.hackedLinkout}
              />
            </div>
          </Router>

        </MuiThemeProvider>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App);
