import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Color from 'color';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import StripeElementWrapper from './StripeElementWrapper';
import { injectStripe } from 'react-stripe-elements';
// import AlipayButton from './AlipayButton';
import CardImg from '../../images/cards.png';

import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';

const styles = theme => ({
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    flexWrap: 'no-wrap'
  },
  cards: {
    height: '2em'
  }
});

class BillingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ccNumber: false,
      ccExpiration: false,
      ccCvc: false,
      disable: false
    }
    this.checkState = this.checkState.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (status, val) {
    let newObj = {}
    newObj[status] = val;
    this.setState(newObj);
  };

  checkState() {
    if(this.state.ccNumber && this.state.ccExpiration && this.state.ccCvc) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    // if(!this.props.division.entry_fees) return this.props.history.push('/');
  }

  submit(e) {
    e.preventDefault();

    // if(this.props.disable || this.checkState()) return;
    // this.setState({
    //   disable: true
    // })
    // this.props.stripe.createToken().then(({token}) => {
      this.props.charge();//token.id
    //   this.setState({
    //     disable: false
    //   })
    // }).catch((err) => {
    //   this.setState({
    //     disable: false
    //   })
    // })
  }

  render() {
    return(
      <form onSubmit={(e) => {this.submit(e)}}>
        <CardContent>
          <h1 className="tl">Billing</h1>
          <p className="tl">
            We accept each of the major credit cards listed below.
          </p>
          <Grid
            container
            spacing={16}>
            <Grid
              item
              xs={12}>
              <RadioGroup
                aria-label="Gender"
                name="gender1"
                value={this.props.entryFee}
                onChange={this.props.handleFeeChange}
              >
                {(this.props.division.entry_fees || []).map((fee, idx) => 
                  <FormControlLabel 
                    key={idx} 
                    value={'' + fee.id} 
                    control={<Radio />} 
                    label={<span>$ <strong>{(fee.charge_amount / 100)}</strong>  {fee.charge_currency}</span>} />
                )}
              </RadioGroup>
            </Grid>
            <Grid
              item
              xs={12}>
              <StripeElementWrapper 
                label="Card Number" 
                component={CardNumberElement} 
                handleChange={this.handleChange}
                handle={'ccNumber'}
              />
              <Grid 
                className="tl"
                item 
                xs={12}>
                <small>
                  <img alt="Accepted Cards" className={this.props.classes.cards} src={CardImg} />
                </small>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}>
              <StripeElementWrapper 
                label="Card Expiration" 
                component={CardExpiryElement} 
                handleChange={this.handleChange}
                handle={'ccExpiration'}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}>
              <StripeElementWrapper 
                label="Card CVC" 
                component={CardCVCElement} 
                handleChange={this.handleChange}
                handle={'ccCvc'}
              />
            </Grid>
          </Grid>

          <Grid 
            item 
            xs={12}
            style={{
              textAlign: 'right',
              marginTop: '1.5em'
            }}>
            Total: {(this.props.division.entry_fees) &&
              <strong>{(this.props.division.entry_fees || []).map((fee, idx) => 
                  (fee.id === parseInt(this.props.entryFee)) ? (
                    <span key={fee.id}>$ {fee.charge_amount / 100}</span>
                  ) : ('')
                )}</strong>
            }
          </Grid>
        </CardContent>
        <CardActions className={this.props.classes.buttonWrapper}>
          <Button 
            component={Link}
            className={this.props.classes.errorButton}
            to="/register/waivers">
            Back
          </Button>
          <Button 
            className={this.props.classes.button}
            variant="contained"
            type="submit"
            // disabled={this.checkState() || this.props.disable || this.state.disable}
            onClick={this.submit.bind(this)}
            color={'primary'}>
            {(this.props.disable || this.state.disable) ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </CardActions>
      </form>
    )
  }
}

BillingForm.propTypes = {
  disable: PropTypes.bool,
  charge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleFeeChange: PropTypes.func.isRequired,
  division: PropTypes.object.isRequired,
  entryFee: PropTypes.string.isRequired
}

export default withRouter(withStyles(styles)(injectStripe(BillingForm)));