 import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Color from 'color';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
// light gray 
const styles = theme => ({
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  button: {
    margin: theme.spacing.unit
  },
  primary: {
    color: theme.palette.primary.main,
  },
  activeCard: {
    background: '#e3e3e3'
  },
  division: {
    transition: '0.3s all'
  },
  buttonWrapper: {
    justifyContent: 'flex-end'
  },
  divisonWrapper: {
    justifyContent: 'center'
  }
});

class Division extends Component {

  constructor(props) {
    super(props);  
    this.state = {
      competition: {},
      divisions: [],
      pricing: '25$',
      selectedDivison: {}
    };

    this.checkState = this.checkState.bind(this);
    this.setTheSelectedVal = this.setTheSelectedVal.bind(this);
    this.switchDivision = this.switchDivision.bind(this);
    this.save = this.save.bind(this);
  }

  setTheSelectedVal(division) {
    // if(division.hint_gender.toUpperCase() === this.props.member.gender.toUpperCase()) this.switchDivision(division);
  }

  componentDidMount() {
    // if(!this.props.member.accessToken) return this.props.history.push('/register/');

    fetch(process.env.REACT_APP_API_URL + '/sports/competitions/' + process.env.REACT_APP_COMPETITION_ID, {
      method: 'GET',
      headers:  new Headers({
        // 'Authorization': 'Bearer ' + this.props.member.accessToken,
        'Content-Type' : 'application/vnd.btwb'
      })
    }).then(res => {
      if(!res.ok) throw Error();
      return res.json();
    }).then(comp => {
      comp.divisions.map(div => {
        return this.setTheSelectedVal(div);
      })

      return this.setState({
        competition: comp,
        divisions: comp.divisions,
        selectedDivison: comp.divisions[0]
      })
    }).catch(err => {
      return this.props.errorCatchHelper(err);
    })
  }

  switchDivision(division) {

    if(this.state.selectedDivison === division.id) return;
    this.setState({
      selectedDivison: division
    })
  }

  checkState() {
    if(!this.state.selectedDivison.price) return true;
    return false;
  }

  getMember() {

    return fetch(process.env.REACT_APP_API_URL + '/sports/registrations?sports_division_id=' + this.state.selectedDivison.id, {
      method: 'GET',
      headers:  {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.member.accessToken,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if(!res.ok) {
        if(res.json) {
          throw res;
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json();
    }).then(reg => {
      console.log(reg);
      if(reg.registrations.length > 0) {
        return this.props.updateMember({registration: reg.registrations[0], division: this.state.selectedDivison}, this.props.history.push.bind(this, '/register/waivers'));
      } else {
        throw Error('There was an issue setting up your registration. Please contact support@crossfitbtwb.com for more information')
      }
    }).catch(err => {
      this.setState({
        disable: false
      })
      return this.props.errorCatchHelper(err);
    })
  }

  save(e) {
    e.preventDefault(); 

    return this.props.history.push('/register/waivers');

    if(this.state.disable === true) return;
    this.setState({
      disable: true
    });

    return fetch(process.env.REACT_APP_API_URL + '/sports/registrations', {
        method: 'POST',
        headers:  {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.props.member.accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({member_id: this.props.member.id, sports_division_id: this.state.selectedDivison.id})
    }).then(res => {
      if(!res.ok) {
        if(res.json) {
          throw res;
        } else {
          throw Error(res.statusText);
        }
      }

      return res.json();
    }).then(response => {
      this.props.updateMember({registration: response, division: this.state.selectedDivison}, this.props.history.push.bind(this, '/register/waivers'));
    }).catch(err => {
      if(err.message) {
        if(err.message === 'Registration is Closed') {
          return this.props.errorCatchHelper(err);
        }
      }
      // if we fail, check if registration exists first
      return this.getMember();
    })
  }

  render() {
    return(
      <div className="animated fadeIn">
        <Grid 
          container
         justify="center">
          <Grid 
            md={8}
            item>
            <form onSubmit={this.save}>
              <Card>
                <CardContent>
                  <div>
                    <h1 className="tl">Divisions</h1>
                    <p className="tl">Select the division you wish to compete in below.</p>
                  </div>
                  <Grid 
                    container 
                    className={this.props.classes.divisonWrapper}
                    spacing={16}>
                    {this.state.divisions.map((division, idx) => 
                      <Grid 
                        className="pointer"
                        item 
                        key={division.id}
                        lg={4}
                        md={6}
                        xs={12}
                        onClick={this.switchDivision.bind(this, division)}>
                        <Card className={this.props.classes.division + 
                            (this.state.selectedDivison.id === division.id ? ' ' + this.props.classes.activeCard : '')}
                          >
                          <CardContent>
                            <h4>{division.display_name}</h4>
                            <p>{/*division.description*/}</p>
                            <Radio
                              checked={this.state.selectedDivison.id === division.id} 
                              onChange={this.switchDivision.bind(this, division)}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <CardActions className={this.props.classes.buttonWrapper}>
                  <Button 
                    component={Link}
                    className={this.props.classes.errorButton}
                    to="/register/additional-info">
                    Back
                  </Button>
                  <Button 
                    className={this.props.classes.button}
                    variant="contained"
                    // disabled={!this.state.selectedDivison.id || this.state.disable}
                    type="submit"
                    color={'primary'}>
                    {this.state.disable ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <span>Next</span>
                    )}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Division.propTypes = {
  member: PropTypes.object.isRequired,
  errorCatchHelper: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(Division));