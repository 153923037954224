import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Color from 'color';
import ReactPhoneInput from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoSuggest from './AutoSuggest';
import AutoCompleteGoogle from './AutoCompleteGoogle';
import dataSet from '../../data/countries';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  buttonWrapper: {
    justifyContent: 'flex-end'
  },
  oddPassword: {
    height: '2rem'
  }
});

class AdditionalInfo extends Component {

    constructor(props) {
    super(props)

    this.state = {
      addressId: null,
      street: '',
      city: '',
      postal_code: '',
      state: '',
      country: '',
      nationality: {
        id: null,
        member_id: this.props.member.id,
        name: 'nationality',
        value: '',
        updated_at: null
      },
      phone: '',
      tShirtSize: {
        id: null,
        member_id: this.props.member.id,
        name: 'tShirtSize',
        value: '',
        updated_at: null
      },
      height: '',
      inches: '',
      weight: '',
      disable: false,
      metric: false
    }

    this.checkState = this.checkState.bind(this);
    this.getMember = this.getMember.bind(this);
    this.handleAutoSuggestChange = this.handleAutoSuggestChange.bind(this);
    this.save = this.save.bind(this);
  }

  handleNestedAttributes(attributeName, value) {
    if(value.target) value = value.target.value;
    this.setState((prevState) => {
      return {
        [attributeName]: {
          id: prevState[attributeName].id,
          member_id: prevState[attributeName].member_id,
          name: attributeName,
          value: value,
          updated_at: prevState[attributeName].updated_at
        }
      }
    })
  }

  handleChange (name, event) {

    if(name === 'metric') {
      let metricVal = event.target.value == 'true'; // eslint-disable-line eqeqeq
      let weightVal = metricVal ? (this.state.weight / 2.2046) : (this.state.weight * 2.2046);
      return this.setState({
        metric: metricVal,
        weight: Math.round(weightVal * 10) / 10
      })
    }
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAutoSuggestChange(name, eventName) {
    this.setState({
      [name]: eventName
    })
  }

  handleFootChange(e) {
    let val = e.target.value;
    if(!val) val = 0;
    const prevMondo = this.state.height % 12;
    if(isNaN(prevMondo) || isNaN(val)) return;
    this.setState({
      height: (val * 12) + prevMondo
    });
  }

  handleInchChange(e) {
    let val = e.target.value;
    this.setState({
      inches: val || 0 
    })
  }

  checkState() {
    let opposite = false;

    for(let key in this.state) {
      if(key === 'metric' || key === 'disable' || key === 'addressId' || key === 'inches') continue;
      if(!this.state[key] || this.state[key] === '+') opposite = true;
    }
    if(!this.state.nationality.value) opposite = true;
    if(!this.state.tShirtSize.value) opposite = true;
    return opposite;
  }

  getMember() {

    // return;
    fetch(process.env.REACT_APP_API_URL + '/accounts/members/' + this.props.member.id, {
      method: 'GET',
      headers:  new Headers({
        'Authorization': 'Bearer ' + this.props.member.accessToken
      })
    }).then(res => {
      return res.json();
    }).then(response => {
      const memberObj = {
        birthday: response.birthday ? response.birthday : '',
        email: response.email ? response.email : '',
        firstname: response.first_name ? response.first_name : '',
        lastname: response.last_name ? response.last_name : '',
        phone: response.phone ? response.phone : '',
        street: response.address.street ? response.address.street : '',
        state: response.address.state ? response.address.state : '',
        postal_code: response.address.postal_code ? response.address.postal_code : '',
        city: response.address.city ? response.address.city : '',
        country: response.address.country ? response.address.country : '',
        addressId: response.address.id ? response.address.id : this.state.addressId,
      };

      response.member_profile_properties.map((attr, idx) => {
        return memberObj[attr.name] = attr;
      });

      if(response.weighin) {
        memberObj.weight = response.weighin.weight;
        memberObj.height = response.weighin.height;
        memberObj.metric = response.weighin.metric;
        memberObj.inches = (!response.weighin.metric ? (response.weighin.height % 12) : 0);
      }

      this.setState(memberObj);

      this.props.updateMember(memberObj);
    })
  }

  componentDidMount() {
    // if(!this.props.member.accessToken) return this.props.history.push('/register/');
    // this.getMember();
  }

  save(e) {
    return this.props.history.push('/register/divisions');
    e.preventDefault();

    if(this.state.disable) return;
    this.setState({
      disable: true
    });

    let heightFunction = () => {
      if(this.state.metric) return this.state.height;
      let newheight = Math.floor(parseInt(this.state.height) / 12) * 12;
      newheight = newheight + parseInt(this.state.inches);
      return newheight;
    }

    let builtObject = {
      member: {
        id: this.props.member.id,
        // birthday: this.props.member.birthday,
        member_profile_properties_attributes: [
          this.state.tShirtSize,
          this.state.nationality
        ],
        address_attributes: {
          street: this.state.street,
          city: this.state.city,
          state: this.state.state,
          postal_code: this.state.postal_code,
          country: this.state.country,
          id: this.state.addressId
        },
        phone: this.state.phone,
        weighin: {
          height: heightFunction(),
          weight: this.state.weight,
          metric: this.state.metric,
          note: 'competition_weighin'
        }
      }
    }

    fetch(process.env.REACT_APP_API_URL + '/accounts/members/' + this.props.member.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.member.accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(builtObject)
    }).then(res => {
      if(!res.ok) {
        if(res.json) {
          throw res;
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json();
    }).then(response => {
      this.props.updateMember(response);
      this.props.history.push('/register/divisions');
    }).catch(e => {
      this.setState({
        disable: false
      })
      this.props.errorCatchHelper(e);
    })
  }


  render() {
    return(<div className="animated fadeIn">
      <Grid 
        container
         justify="center">
        <Grid
          item
          md={8}>
          <form onSubmit={this.save}>
            <Card>
              <CardContent className={(this.state.disable ? ' disabled' : '')}>
                <h1 className="tl">Additional Information</h1>
                  <Grid
                    container
                    spacing={16}>
                    <Grid
                      item
                      xs={12}>
                      <AutoCompleteGoogle
                        handleChange={this.handleChange}
                        handleAutoSuggestChange={this.handleAutoSuggestChange.bind(this)}
                        address={this.state.address}
                        street={this.state.street}
                        city={this.state.city}
                        postal_code={this.state.postal_code}
                        state={this.state.state}
                        country={this.state.country}
                      />
                    </Grid>
                    {/*<Grid
                      item
                      xs={12}>
                      <AutoSuggest 
                        onSelect={this.handleNestedAttributes.bind(this)} 
                        suggestions={dataSet}
                        whatToUpdate={'nationality'}
                        currentValue={this.state.nationality.value}
                      />
                    </Grid>*/}
                    <Grid
                      item
                      xs={12}>
                      {/*<TextField
                        id="phone"
                        label="Phone Number"
                        autoComplete="tel"
                        type="tel"
                        inputProps={{
                          pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        }}
                        fullWidth
                        className={this.props.classes.fields}
                        value={this.state.phone}
                        onChange={this.handleChange.bind(this, 'phone')}
                        margin="normal"
                      />*/}
                      <ReactPhoneInput 
                        defaultCountry={'us'} 
                        value={this.state.phone}
                        label={'Phone Number'}
                        containerClass={this.props.classes.fields}
                        inputClass={'input-skeptic'}
                        onChange={this.handleAutoSuggestChange.bind(this, 'phone')}
                      />
                    </Grid>
                    {/*<Grid
                      item
                      xs={12}>

                      <FormControl 
                        fullWidth
                        component="fieldset" 
                        className={this.props.classes.formControl}>
                        <FormHelperText>T-shirt Size (Rogue Men’s/Unisex Style)</FormHelperText>
                        <RadioGroup
                          aria-label="tshirt"
                          name="tshirt"
                          className={this.props.classes.group}
                          value={this.state.tShirtSize.value}
                          onChange={this.handleNestedAttributes.bind(this, 'tShirtSize')}
                          row={true}>
                          <FormControlLabel value={'xs'} control={<Radio />} label="extra small" />
                          <FormControlLabel value={'small'} control={<Radio />} label="small" />
                          <FormControlLabel value={'medium'} control={<Radio />} label="medium" />
                          <FormControlLabel value={'large'} control={<Radio />} label="large" />
                          <FormControlLabel value={'xl'} control={<Radio />} label="extra large" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>*/}
                    <Grid
                      item
                      xs={12}>
                      <FormHelperText>Height and Weight</FormHelperText>
                      <FormControl 
                        fullWidth
                        component="fieldset" 
                        className={this.props.classes.formControl}>
                        <RadioGroup
                          aria-label="Metric"
                          name="metric"
                          className={this.props.classes.group}
                          value={this.state.metric + ''}
                          onChange={this.handleChange.bind(this, 'metric')}
                          row={true}>
                          <FormControlLabel value={'false'} control={<Radio />} label="Imperial" />
                          <FormControlLabel value={'true'} control={<Radio />} label="Metric" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}>
                      {this.state.metric ? (
                        
                        <TextField
                          id="height"
                          label="cm"
                          fullWidth
                          className={this.props.classes.fields}
                          value={this.state.height}
                          onChange={this.handleChange.bind(this, 'height')}
                          margin="normal"
                        />

                        ) : (    
                        <Grid 
                          container
                          alignItems="center"
                          spacing={16}>
                          <Grid 
                            item
                            xs={5}>

                            <TextField
                              id="feet"
                              label="feet"
                              // fullWidth
                              className={this.props.classes.fields}
                              value={Math.floor(this.state.height / 12)}
                              onChange={this.handleFootChange.bind(this)}
                              margin="normal"
                            />
                          </Grid>
                          <Grid 
                            item
                            xs={5}>
                            <TextField
                              id="height"
                              label="inches"
                              // fullWidth
                              className={this.props.classes.fields}
                              value={this.state.inches}
                              onChange={this.handleInchChange.bind(this)}
                              margin="normal"
                            />
                          </Grid>
                        </Grid>
                        )
                      }
                    </Grid>
                    <Grid
                      item
                      xs={6}>
                      <Grid item>
                        <TextField
                          id="weight"
                          label={this.state.metric ? 'kg' : 'lbs'}
                          fullWidth
                          className={this.props.classes.fields}
                          value={this.state.weight}
                          onChange={this.handleChange.bind(this, 'weight')}
                          margin="normal"
                        /> 
                      </Grid>
                    </Grid>
                  </Grid>
              </CardContent>
              <CardActions className={this.props.classes.buttonWrapper}>
                {/*<Button 
                  component={Link}
                  className={this.props.classes.errorButton}
                  to="/register">
                  Back
                </Button>*/}
                <Button 
                  className={this.props.classes.button}
                  variant="contained"
                  // disabled={this.state.disable || this.checkState()}
                  type="submit"
                  color={'primary'}>
                  {this.state.disable ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    <span>Next</span>
                  )}
                </Button>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </div>)
  }
}

AdditionalInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  updateMember: PropTypes.func.isRequired,
  errorCatchHelper: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(AdditionalInfo));