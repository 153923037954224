import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

let globoTimeout;

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}


  
function getGyms(query, token) {
  const inputValue = deburr(query.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  fetch('https://us-central1-btwb-thewire.cloudfunctions.net/ProdGymSearchName?q=' + query, {
    method: 'GET'
  }).then(res => {
    return res.json();
  }).then(response => {
    return this.setState({
      suggestions: inputLength === 0
    ? []
    : response.GymProfiles.filter(suggestion => {
        const keep = count < 5;

        if (keep) {
          count += 1;
        }

        return keep;
      })
    });
  });
}

function getSuggestions(value, token) {
  window.clearTimeout(globoTimeout);

  const inputValue = deburr(value.trim()).toLowerCase();
  // const inputLength = inputValue.length;
  // let count = 0;

  return globoTimeout = window.setTimeout(getGyms.bind(this, inputValue, token), 500);
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = theme => ({
  root: {
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class IntegrationAutosuggest extends React.Component {
  state = {
    single: '',
    popper: this.props.currentValue ? this.props.currentValue : '',
    suggestions: [],
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    getSuggestions.call(this, value, this.props.accessToken)
    // this.setState({
    //   suggestions: getSuggestions.call(this, value, this.props.accessToken),
    // });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.DisplayName, query);
    const parts = parse(suggestion.DisplayName, matches);

    return (
      <MenuItem selected={isHighlighted} id={suggestion.GymId} onClick={this.sendActualRequest.bind(this, suggestion.GymId)} component="div">
        <div id={suggestion.id}>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  }

  sendActualRequest = (val) => {
    this.props.onSelect(this.props.whatToUpdate, val);
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue, method }) => {
    if(!newValue) newValue = event.target.parentNode.innerText;
    
    this.setState({
      popper: newValue,
    });
  };

  componentWillReceiveProps(nextProps) {
    
    if(nextProps.currentValue !== this.props.currentValue) {
      return this.setState({
        popper: nextProps.currentValue
      })
    }
  }

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <div className={classes.divider} />
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: this.props.whatToUpdate.charAt(0).toUpperCase() + this.props.whatToUpdate.slice(1),
            placeholder: 'Crossfit HQ',
            value: this.state.popper,
            onChange: this.handleChange.bind(this),
            inputRef: node => {
              this.popperNode = node;
            },
            InputLabelProps: {
            },
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
              <Paper
                square
                {...options.containerProps}
                style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  // suggestions: PropTypes.array.isRequired,
  whatToUpdate: PropTypes.string.isRequired,
  currentValue: PropTypes.string
};

export default withStyles(styles)(IntegrationAutosuggest);