import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import StripeInput from './StripeInput';
// import Errors from './Errors'

class StripeElementWrapper extends Component {
  static displayName = 'StripeElementWrapper'

  static propTypes = {
    component: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handle: PropTypes.string.isRequired
  }

  state = {
    focused: false,
    empty: true,
    error: false
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleChange = changeObj => {
    // console.log(changeObj);
    this.setState({
      error: changeObj.error,
      empty: changeObj.empty,
    }, () => {
      if(!this.state.error && !this.state.empty) {
        this.props.handleChange(this.props.handle, true);
      } else {
        this.props.handleChange(this.props.handle, false);
      }
    })
  }

  render() {
    const { component, label } = this.props
    const { focused, empty, error } = this.state

    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel
            focused={focused}
            shrink={focused || !empty}
            error={!!error}>
            {label}
          </InputLabel>
          <Input
            fullWidth
            inputComponent={StripeInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            inputProps={{ component }}
            error={this.state.error ? true : false}
            placeholder={this.props.placeholder ? this.props.placeholder : ''}
          />
        </FormControl>
      </div>
    )
  }
}

export default StripeElementWrapper;