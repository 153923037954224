import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Color from 'color';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import MainForm from './BillingForm';
// import AlipayButton from './AlipayButton';

import { StripeProvider, Elements } from 'react-stripe-elements';

const styles = theme => ({
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  buttonWrapper: {
    justifyContent: 'flex-end'
  },
  cards: {
    height: '2em'
  }
});

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      ccNumber: false,
      ccExpiration: false,
      ccCvc: false,
      entryFee: this.props.member.division.entry_fees ? this.props.member.division.entry_fees[0].id : 0
    }
    this.checkState = this.checkState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.charge = this.charge.bind(this);
    this.handleFeeChange = this.handleFeeChange.bind(this);
  }

  handleFeeChange(e) {
    this.setState({
      entryFee: parseInt(e.target.value)
    })
  }

  handleChange (status, val) {
    let newObj = {}
    newObj[status] = val;
    this.setState(newObj);
  };

  checkState() {
    if(this.state.ccNumber && this.state.ccExpiration && this.state.ccCvc) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    // if(!this.props.member.registration.id || ! this.props.member.division) return this.props.history.push('/register/');
  }

  charge(token) {

    this.setState({
      disable: true
    });
    return this.props.history.push('/register/success');

    if(!this.props.member.division.entry_fees) {
      this.setState({
        disable: false
      })
      return this.props.errorCatchHelper('Billing isn\'t enabled yet! Please set up the correct division information.');
    };

    // return this.props.history.push('/success');

    return fetch(process.env.REACT_APP_API_URL + '/sports/registrations/' + this.props.member.registration.id + '/pay_by_charge', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.member.accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        registration_id: this.props.member.registration.id, 
        member_id: this.props.member.id,
        stripe_token: token,
        entry_fee_id: this.state.entryFee
      })
    }).then(res => {
      if(!res.ok) {
        if(res.json) {
          throw res;
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json();
    }).then(response => {
      // console.log(this.props)
      return this.props.history.push('/register/success');
    }).catch(err => {
      console.log(err)
      this.setState({
        disable: false
      })
      return this.props.errorCatchHelper(err)
    })

  }

  render() {
    return(
      <div  className="animated fadeIn">
        {/*<h1 className="tl">Billing</h1>
        <p className="tl">We accept each of the major credit cards listed below.</p>*/}
        <StripeProvider apiKey={'pk_live_z948vvhevTD6dnm3tS6LXbom'}>
          <Elements>
            <Grid 
              container
              justify="center">
              <Grid
                item
                md={8}
                sm={12}
                xs={12}>
                <Card>
                  <MainForm 
                    charge={this.charge}
                    member={this.props.member}
                    errorCatchHelper={this.props.errorCatchHelper}
                    disable={this.state.disable}
                    handleFeeChange={this.handleFeeChange}
                    division={this.props.member.division}
                    entryFee={'' + this.state.entryFee}
                  />
                </Card>
              </Grid>
            </Grid>
          </Elements>
        </StripeProvider>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Billing));