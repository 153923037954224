import React, { Component } from 'react';
import PropTypes from 'prop-types'; 
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  snackbar: {
    backgroundColor: theme.palette.error.dark,
    color: '#FFF'
  } 
});


class SnackbarPartial extends Component {
  constructor(props) {
    super(props);

    this.goToLink = this.goToLink.bind(this);
    // console.log(this);
  }

  goToLink() {
    this.props.toggleError();
    return this.props.history.push('/register/login');
  }

  render() {

    return(
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.props.error ? this.props.error : false}
        onClose={this.props.toggleError}
      >

        <SnackbarContent
          className={this.props.classes.snackbar}
          aria-describedby="message-id"
          message={<div id="message-id">
            <span>{this.props.message} </span>
            {this.props.hackedLinkout &&
               <span style={{color: '#0000ee', cursor: 'pointer'}} onClick={this.goToLink}>Please log in here.</span>
            }
          </div>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.toggleError}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </Snackbar>
    )
  }
}

SnackbarPartial.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node,
  onClose: PropTypes.func,
  toggleError: PropTypes.func.isRequired,
  hackedLinkout: PropTypes.bool
}

export default withRouter(withStyles(styles)(SnackbarPartial));