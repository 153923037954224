import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Color from 'color';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AutoSuggestGym from './AutoSuggestGym';

const styles = theme => ({
  fields: {
    // margin: theme.spacing.unit
  },
  fieldContainer: {
    padding: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit,
  },
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonWrapper: {
    justifyContent: 'flex-end'
  },
  oddPassword: {
    height: '2em'
  }
});

class SignUp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      member: {
        first_name: this.props.member.name ? this.props.member.name.split(' ')[0] : '',
        last_name: this.props.member.name.split(' ').length > 0 ? this.props.member.name.split(' ')[1] : '',
        email: this.props.member.email ? this.props.member.email : '',
        birthday: '',
        gender: '',
        gym_id: ''
      },
      password: '',
      disable: false
    }

    this.checkState = this.checkState.bind(this);
    this.signUpFunc = this.signUpFunc.bind(this);
    this.actualSaveFetch = this.actualSaveFetch.bind(this);
  }

  handleChange (name, event) {
    let tempMember = this.state.member;
    tempMember[name] = event.target.value;
    this.setState({
      member: tempMember
    });
  };

  handlePassword(event) {
    this.setState({
      password: event.target.value
    })
  }

  checkState() {
    let opposite = false;

    if(this.state.disable) return opposite = true;

    for(let key in this.state) {
      if(key === 'disable') continue;
      if(!this.state[key]) opposite = true;
    }

    for(let key in this.state.member) {
      if(key === 'gym_id') continue;
      if(key === 'last_name') continue;
      if(!this.state.member[key]) opposite = true;
    }
    return opposite;
  }

  handleAutoSuggestChange(name, eventName) {
    let member = this.state.member;
    member.gym_id = eventName;
    this.setState({member: member})
  }

  actualSaveFetch() {

    let member = this.state.member;

    // member.first_name = member.firstname;
    // member.last_name = member.lastname;

    return fetch(process.env.REACT_APP_API_URL + '/accounts/members', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:  JSON.stringify({...member, password: this.state.password})
    }).then(res => {

      if(!res.ok) {
        if(res.json) {
          throw res;
        } else {
          throw Error(res.statusText); 
        }
      }
      return res.json();
    }).then(response => {
      member.accessToken = response.access_token;
      member.id = response.member.id;
      this.props.updateMember(member, this.props.history.push.bind(this, '/register/additional-info'));

      // this.setState({
      //   disable: false
      // })

    }).catch(err => {

      this.setState({
        disable: false
      })

      return this.props.errorCatchHelper(err);

    });
  }

  signUpFunc(e) {
    e.preventDefault();
    return this.props.history.push('/register/additional-info');
    if(this.state.disable) return;

    this.setState({
      disable: true
    })

    if(this.state.member.birthday.split('/').length !== 3 && this.state.member.birthday.split('-').length !== 3) {
      this.setState({
        disable: false
      })

      return this.props.errorCatchHelper('Please enter your birthday as MM/DD/YYYY!');
    }

    // catch if 
    if(isNaN(parseInt(this.state.member.gym_id))) {
      let member = this.state.member;
      member.gym_id = 2;
      this.setState({
        member: member
      })
    }

    if(this.state.member.birthday.split('/')[0].length !== 4 && this.state.member.birthday.split('-').length !== 3) {
      
      let todayDate = Date.parse(new Date().toString());
      let birthdayDate = Date.parse(this.state.member.birthday);
      if(todayDate < birthdayDate) {
        this.setState({
          disable: false
        })
        return this.props.errorCatchHelper('Please do not enter a future date!')
      }


      let splitbirthday = this.state.member.birthday.split('/');

      let member = this.state.member;
      member.birthday = splitbirthday[2] + '-' + splitbirthday[0] + '-' + splitbirthday[1];
      return this.setState({
        member: member
      },() => {
        return this.actualSaveFetch();
      })
    }

    // double the fun 
    let todayDate = Date.parse(new Date().toString());
    let birthdayDate = Date.parse(this.state.member.birthday);
    if(todayDate < birthdayDate) {
      this.setState({
        disable: false
      })
      return this.props.errorCatchHelper('Please do not enter a future date!')
    }

    return this.actualSaveFetch();

  }

  changeLocation(e) {
    e.preventDefault();
    this.props.history.push('/register/login');
  }

  render() {
    return(<div className={'animated fadeIn'}>
      <Grid 
        container
         justify="center">
        <Grid
          item
          md={8}>
          <form onSubmit={this.signUpFunc}>
            <Card>
              <CardContent className={(this.state.disable ? ' disabled' : '')}>
                <h1 className="tl">Create a CrossFit btwb Account</h1>
                <p className="tl">The Saved By The Barbell Challenge will be hosted by CrossFit btwb, the Official CrossFit® Workout Tracker. You'll need an account to log your score for this event. <b>Create one below</b> or <b><a href="/register/login" onClick={this.changeLocation.bind(this)}>Click here to login</a></b></p>
                <Grid
                  container
                  spacing={16}>
                  <Grid
                    item
                    xs={6}>
                    <TextField
                      id="first-name"
                      label="First Name"
                      autoComplete="given-name"
                      fullWidth
                      className={this.props.classes.fields}
                      value={this.state.member.first_name}
                      onChange={this.handleChange.bind(this, 'first_name')}
                      margin="normal"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}>
                    <TextField
                      id="last-name"
                      label="Last Name"
                      autoComplete="family-name"
                      fullWidth
                      className={this.props.classes.fields}
                      value={this.state.member.last_name}
                      onChange={this.handleChange.bind(this, 'last_name')}
                      margin="normal"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <TextField
                      id="email-name"
                      label="Email"
                      autoComplete="email"
                      fullWidth
                      className={this.props.classes.fields}
                      value={this.state.member.email}
                      onChange={this.handleChange.bind(this, 'email')}
                      margin="normal"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <TextField
                      id="password"
                      label="Password"
                      fullWidth
                      type="password"
                      className={this.props.classes.fields}
                      value={this.state.password}
                      onChange={this.handlePassword.bind(this)}
                      margin="normal"
                      InputProps={{
                        className: this.props.classes.oddPassword,
                      }} 
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <AutoSuggestGym 
                      onSelect={this.handleAutoSuggestChange.bind(this)}
                      whatToUpdate={'gym'}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}>
                    <TextField
                      id="birthdate"
                      label="Birthdate"
                      autoComplete="bday"
                      fullWidth
                      type="date"
                      defaultValue=""
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={'MM/DD/YYYY'}
                      className={this.props.classes.fields}
                      value={this.state.member.firstName}
                      onChange={this.handleChange.bind(this, 'birthday')}
                      margin="normal"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}>
                    <FormControl 
                      fullWidth
                      component="fieldset" 
                      className={this.props.classes.formControl}>
                      <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        className={this.props.classes.group}
                        value={this.state.member.gender}
                        onChange={this.handleChange.bind(this, 'gender')}>
                        <FormControlLabel value="Female" control={<Radio />} label="Female" />
                        <FormControlLabel value="Male" control={<Radio />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={this.props.classes.buttonWrapper}>
                <Button 
                  component={Link}
                  className={this.props.classes.errorButton}
                  to="/register/">
                  Back
                </Button>
                <Button 
                  type="submit"
                  className={this.props.classes.button}
                  variant="contained"
                  // disabled={this.checkState()}
                  color={'primary'}>
                  {this.state.disable ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    <span>Next</span>
                  )}
                </Button>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </div>);
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  errorCatchHelper: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired,
  member: PropTypes.object
};

export default withRouter(withStyles(styles)(SignUp));