import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import Color from 'color';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Apple from '../../images/apple.svg';
import Google from '../../images/en_badge_web_generic.png';

const styles = theme => ({
  buttonWrapper: {
    justifyContent: 'flex-end'
  },
  primary: {
    color: theme.palette.primary.main,
  },
  apple: {
    display: 'block',
    width: '104px',
    margin: '0px !important'
  },
  google: {
    display: 'block',
    width: '108px',
  },
  marginHack: {
    marginRight: '0.5rem !important'
  }
});

class Success extends Component {
  render() {
    return(<div className="animated fadeIn">
      <Grid 
        container
         justify="center">
        <Grid
          item
          md={8}
          sm={12}
          xs={12}>
          <Card>
            <CardContent>
              <h1 className="tl">Success</h1>
              <p className="tl">A confirmation email has been sent to {this.props.member.email}. Be sure to read it for important details regarding the upcoming competition. Good luck!</p>
              <h1 className="tl">Next Steps</h1>
              <p className="tl">You must log the workout using the CrossFit® btwb app. Download it below!</p>
              <Grid
                className="tl mt2" 
                item
                xs={8}>
                <Grid 
                  container 
                  justify="flex-start" 
                  alignItems="center">
                  <Grid 
                    item 
                    className={this.props.classes.marginHack}>
                    <a className={'mw4 mr2 ' + this.props.classes.google} 
                      href='https://play.google.com/store/apps/details?id=com.beyondthewhiteboard.johnnyutah&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                      <img alt='Get it on Google Play' src={Google} />
                    </a>
                  </Grid>
                  <Grid 
                    item
                    className={this.props.classes.marginHack}>
                    <a className={'mw4 ma0 ' + this.props.classes.apple} 
                      href='https://itunes.apple.com/gb/app/crossfit-btwb/id583688662?mt=8'>
                      <img alt='Get it on Google Play' src={Apple}/>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              
            </CardContent>
            <CardActions className={this.props.classes.buttonWrapper}>
              <Grid container>  
                <Grid 
                  item
                  xs={12}
                  className="tr">
                  <a href="https://www.roguefitness.com/invitational">
                    <Button 
                      className={this.props.classes.button}
                      variant="contained"
                      color={'secondary'}>
                      Continue
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>);
  }
}

Success.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Success);