import React from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss';
import App from './Containers/App/';
import * as serviceWorker from './serviceWorker';

if(process.env.REACT_APP_ENV !== 'development' && window.location.protocol !== 'https:') {
  window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
