import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  fields: {
    // margin: theme.spacing.unit
  }
});

class AutoCompleteGoogle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      street: '',
      city: '',
      state: '',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    }

    this.myRef = React.createRef();
  }

  componentDidMount() {

    // const google = window.google;
    // if (typeof google === 'undefined') {
    //   console.warn('Google Places was not initialized. LocationSearchBox will not function.');
    //   return;
    // }

    // const { country, onPlaceChanged } = this.props;
    // const { places } = google.maps;

    // let options;

    // if (country) {
    //   options = {
    //     componentRestrictions: { country }
    //   };
    // }

    // const input = this.myRef.current;
    // // console.log(this.myRef)


    // if (!input._autocomplete) {
    //   input._autocomplete = new places.Autocomplete(input, options);

    //   const thisFunction = () => {
    //     const place = input._autocomplete.getPlace();

    //     onPlaceChanged && onPlaceChanged(place);
    //     this.setState({
    //       address: place.name
    //     }, () => {

    //       this.props.handleAutoSuggestChange('address', place.name);
    //     })
    //     console.log(onPlaceChanged)
    //     for (var i = 0; i < place.address_components.length; i++) {
    //       var addressType = place.address_components[i].types[0];
    //       if (this.state[addressType]) {
    //         var val = place.address_components[i][this.state[addressType]];
    //         this.setState({[addressType]: val}, () => {});
    //       }
    //     }
    //   }

    //   input._autocomplete.addListener('place_changed', thisFunction.bind(input._autocomplete));
    // }
  }

  handleChange(val, e) {

    this.props.handleAutoSuggestChange(val, e.target.value);
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.address)
    // if(nextProps.address && this.state.name !== nextProps.address) {
    //   this.setState({
    //     address: nextProps.address
    //   })
    // }
  }

  render() {
    return(
      <Grid 
        container
        spacing={16}>
        <Grid
          item
          xs={12}>
          <TextField
            id="address"
            label="Shipping Address"
            autoComplete="shipping street-address"
            fullWidth
            value={this.props.street}
            className={this.props.classes.fields}
            onChange={this.handleChange.bind(this, 'street')}
            margin="normal"
          />

        </Grid>
        <Grid
          item
          sm={4}
          xs={6}>
          <TextField
            id="city"
            label="City/Providence"
            name="address-level2"
            autoComplete="shipping address-level2"
            fullWidth
            value={this.props.city}
            className={this.props.classes.fields}
            onChange={this.handleChange.bind(this, 'city')}
            margin="normal"
          />
        </Grid>
        <Grid
          item
          sm={3}
          xs={6}>
          <TextField
            id="state"
            label="State"
            autoComplete="shipping address-level1"
            fullWidth
            value={this.props.state}
            className={this.props.classes.fields}
            onChange={this.handleChange.bind(this, 'state')}
            margin="normal"
          />
        </Grid>
        <Grid
          item
          sm={3}
          xs={6}>
          <TextField
            id="zip"
            label="Zip Code"
            autoComplete="shipping postal-code"
            fullWidth
            value={this.props.postal_code}
            className={this.props.classes.fields}
            onChange={this.handleChange.bind(this, 'postal_code')}
            margin="normal"
          />
        </Grid>
        <Grid
          item
          sm={2}
          xs={6}>
          <TextField
            id="country"
            label="Country"
            autoComplete="shipping country"
            fullWidth
            value={this.props.country}
            className={this.props.classes.fields}
            onChange={this.handleChange.bind(this, 'country')}
            margin="normal"
          />
        </Grid>
      </Grid>
    )
  }
}

AutoCompleteGoogle.propTypes = {
  address: PropTypes.string,
  handleChange: PropTypes.func.isRequired
}

export default withStyles(styles)(AutoCompleteGoogle);