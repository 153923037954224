import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    color: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    display: 'block',
    width: '220px',
    margin: theme.spacing.unit + 'px 0'
  },
  primary: {
    color: theme.palette.primary.main,
  },
  pContainer: {
    // margin: theme.spacing.unit * 2.5,
    textAlign: 'left'
  },
  pTags: {
    margin: 0 + 'px'
  },
  marginDiff: {
    fontSize: 12 + 'px',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    textAlign: 'left'
  },
  splitPadding: {
/*    padding: theme.spacing.unit * 5,
    borderRight: '1px solid #000',
    borderRadius: (theme.spacing.unit / 2) + 'px 0px 0px ' + (theme.spacing.unit / 2) + 'px',*/
    width: '100%'
  },
  splitPaddingR: {
    padding: theme.spacing.unit * 5,
    borderRadius: '0px '+ (theme.spacing.unit / 2) + 'px ' + (theme.spacing.unit / 2) + 'px 0px',
    width: '40%'
  },
  /*'@media (max-width: 1024px)': {
    splitPadding: {
      width: '100%',
      borderBottom: '1px solid #000',
      borderRight: 'none',
      borderRadius: (theme.spacing.unit / 2) + 'px ' + (theme.spacing.unit / 2) + 'px 0px 0px',
    },
    splitPaddingR: {
      width: '100%',
      borderRadius: '0px 0px '+ (theme.spacing.unit / 2) + 'px ' + (theme.spacing.unit / 2) + 'px',
    }
  }*/
});

class Landing extends Component {
  goToPage() {
    this.props.history.push('/register/sign-up')
  }

  render() {
    return(
      <div className="animated fadeIn">
        <Grid 
          container
         justify="center">
          <Grid 
            md={8}
            item>
                <Grid 
                  container
                  alignItems={'stretch'}>
                  <Card
                    className={this.props.classes.splitPadding}>
                    <CardContent>
                      <div className={this.props.classes.pContainer}>
                        <h1 className={this.props.classes.pTags}>Closed</h1>
                        <p className={''}>Registration is closed until July 15th. Come back then!</p>
                        <p className={''}></p>
                      </div>
                      <a href="https://rogue.btwb.com/leaderboard/index.html">
                        <Button
                          className={this.props.classes.button}
                          variant="contained" 
                          color="primary">
                          Leaderboard
                        </Button>
                      </a>
                        <Button
                          className={this.props.classes.button}
                          variant="contained" 
                          color="secondary"
                          onClick={this.goToPage.bind(this)}>
                          Register
                        </Button>
                    </CardContent>
                  </Card>
                  {/*<Card
                    className={this.props.classes.splitPaddingR}>
                    <p className={this.props.classes.marginDiff}><i>A <a href="https://crossfitbtwb.com" target="_blank">Crossfit BTWB</a> account will be created for you when you complete the registration process.</i></p>
                    <Button
                      component={Link}
                      className={this.props.classes.button}
                      to="/sign-up"
                      variant="contained" 
                      color="secondary">
                      Register Now
                    </Button>
                    <p className={this.props.classes.marginDiff}>Already have a CrossFit btwb account? Sign in below.</p>
                    <Button
                      component={Link}
                      className={this.props.classes.button}
                      to="/login"
                      variant="contained" 
                      color="primary">
                      Sign Into CrossFit btwb
                    </Button>
                  </Card>*/}
                </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Landing);