import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Color from 'color';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  fields: {
    // margin: theme.spacing.unit
  },
  fieldContainer: {
    padding: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit,
  },
  errorButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: Color(theme.palette.error.light).fade(0.92).string()
    }
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonWrapper: {
    justifyContent: 'flex-end'
  },
  oddPassword: {
    height: '2em'
  }
});

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.member.email ? this.props.member.email : '',
      password: '',
      disable: false
    }

    this.checkState = this.checkState.bind(this);
    this.loginFunc = this.loginFunc.bind(this);
  }

  handleChange (name, event) {
    this.setState({
      [name]: event.target.value,
    });
  };

  checkState() {
    let opposite = false;

    if(this.state.disable) return opposite = true;

    for(let key in this.state) {
      if(key === 'disable') continue;
      if(!this.state[key]) opposite = true;
    }
    return opposite;
  }

  loginFunc(e) {

    e.preventDefault();

    if(this.state.disable) return;
    this.setState({
      disable: true
    })

    return fetch(process.env.REACT_APP_API_URL + '/accounts/members/signin', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:  JSON.stringify({email: this.state.email, password: this.state.password})
    }).then(res => {
      if(!res.ok) {
        if(res.json) {
          throw res;
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json();
    }).then(response => {
      let member = {};

      // I can set the member with the whole response, but w/e
      member.accessToken = response.access_token;
      member.birthday = response.member.birthdate;
      member.name = response.member.name;
      member.email = response.member.email;
      member.gender = response.member.gender;
      member.id = response.member.id;

      this.props.updateMember(member, this.props.history.push.bind(this, '/register/additional-info'));
      
      // this.setState({
      //   disable: false
      // })

    }).catch(err => {
      
      this.setState({
        disable: false
      })

      return this.props.errorCatchHelper(err);
    })
  }

  updateHistory(e){
    e.preventDefault();
    this.props.history.push('/register/sign-up');
  }

  render() {
    return(
      <div  className="animated fadeIn">
        <Grid 
          container
          justify="center">
          <Grid
            item
            md={8}>
            <form onSubmit={this.loginFunc}>
              <Card>
                <CardContent className={(this.state.disable ? ' disabled' : '')}>
                  <h1 className="tl">Sign Into CrossFit® btwb</h1>
                  <p className="tl">Use your CrossFit® btwb credentials to sign in below. Don't have a CrossFit® btwb account yet? <a href="/register/sign-up" onClick={this.updateHistory.bind(this)}>Click here to create an account</a></p>
                  <Grid
                    container
                    spacing={16}>
                    <Grid
                      item
                      xs={12}>
                      <TextField
                        id="email-name"
                        label="Email"
                        type="email"
                        autoComplete="email"
                        fullWidth
                        className={this.props.classes.fields}
                        value={this.state.email}
                        onChange={this.handleChange.bind(this, 'email')}
                        margin="normal"
                        InputProps={{
                          className: this.props.classes.oddPassword,
                        }} 
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}>
                      <TextField
                        id="password"
                        label="Password"
                        autoComplete="current-password"
                        fullWidth
                        type="password"
                        className={this.props.classes.fields}
                        value={this.state.password}
                        onChange={this.handleChange.bind(this, 'password')}
                        margin="normal"
                        InputProps={{
                          className: this.props.classes.oddPassword,
                        }} 
                      />
                      <p className="tl">Forgot your password? Reset it <a target="_blank" rel="noopener noreferrer" href="https://crossfitbtwb.com/forgot_password">here</a></p>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={this.props.classes.buttonWrapper}>
                  <Button 
                    component={Link}
                    className={this.props.classes.errorButton}
                    to="/register/">
                    Back
                  </Button>
                  <Button 
                    className={this.props.classes.button}
                    type="submit"
                    variant="contained"
                    disabled={this.checkState()}
                    color={'primary'}>
                    {this.state.disable ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <span>Next</span>
                    )}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SignIn.propTypes = {
  errorCatchHelper: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired,
  member: PropTypes.object
}

export default withRouter(withStyles(styles)(SignIn));